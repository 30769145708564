<template>
  <footer>
    <p>
      Copyrights 2020 ©. Created with
      <i class="fas fa-heart green-icon"></i> by
      <a href="https://www.dionweb.me" target="_blank">
        <span class="underline">DionWeb</span>
      </a>
    </p>

    <a class="privacy-link" href="privacy">Πολιτική απορρήτου</a>
  </footer>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style lang="scss">
@import "../assets/styles/setup/variables.scss";
@import "../assets/styles/setup/mixins.scss";
@import "../assets/styles/components/footer.scss";
</style>
